

































import { Component, Vue } from 'vue-property-decorator';


@Component({
  components: {},
})
export default class Login extends Vue {
    private loading: boolean = true;
    private showError: boolean = false;
    public errorMessage: string = 'Ошибка';
    public login: string | null = null;
    public password: string | null = null;

    public submit() {
        let self = this;
        this.$store.dispatch('auth/login', {login:this.login, password:this.password})
        .then(function(resp){
            self.$router.push('/');
        }).catch(function(resp){
            (self as any).$bus.$emit('popup',{message:'Неверное имя пользователя или пароль', type:'error'});
        });
    }

}
